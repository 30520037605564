<template>
    <div class="card card_admin animation_fadeFlyInLeft" v-if="show">
        <div style="float: left">
            <h1>Files ({{totalSize}} occupied. {{remainSpace}} remains.)</h1>
        </div>
        <div style="float: right">
            <h3> Loged as {{getRole}} {{ this.$msal.data.user.userName }}</h3>
            <button class="button_submit button_logout" v-on:click="logout()">Log out</button>
        </div>
        <br />
        <br />
        <br />
        <br />
        <div>
            <vue-good-table :id="mainTable"
                            :ref="mainTable"
                            @on-selected-rows-change="selectionChanged"
                            :columns="headers"
                            :rows="filteredFiles"
                            :fixed-header="true"
                            styleClass="vgt-table striped table_admin"
                            :search-options="{ enabled: true, placeholder: '' }"
                            :sort-options="{  enabled: true}"
                            :pagination-options="{ enabled: true, perPage: 20}"
                            :select-options="{enabled: true,selectOnCheckboxOnly: true,selectionText: 'rows selected',clearSelectionText: 'Clear', selectionInfoClass: 'selectionInfoRow'}">
                <div slot="selected-row-actions">
                    <button class="button_submit button_delete" v-on:click="deleteFiles" v-if="isAdmin"> Delete selected</button>
                </div>
                <template slot="table-row" slot-scope="props">
                    <div style="max-width: 250px; word-wrap: break-word;">
                        <span v-if="props.column.field == 'uploadFinished' || props.column.field == 'emailsSend' ">
                            <input type="checkbox" :value="props.formattedRow[props.column.field]" :checked="props.formattedRow[props.column.field]" disabled>
                        </span>
                        <span v-else-if="props.column.field == 'url'">
                            <a :href="props.formattedRow[props.column.field]">Link</a>
                        </span>
                        <span v-else-if="props.column.field == 'delete' && isAdmin">
                            <button class="button_submit button_delete" v-on:click="deleteFile(props.row.fileRequestId)">x</button>
                        </span>
                        <span v-else>
                            {{props.formattedRow[props.column.field]}}
                        </span>
                    </div>
                </template>
                <template slot="table-column" slot-scope="props">
                    <span v-if="props.column.label =='User email'">
                        <label style="margin-right: 5px">{{props.column.label}}</label>
                    </span>
                    <span v-else>
                        {{props.column.label}}
                    </span>
                </template>
                <div slot="table-actions" v-if="isAdmin">
                    <div>
                        <input type="checkbox" id="mineBox" name="mineBox" style="margin-right: 2px" v-model="onlyMine" @change="check($event)" class="checkbox-round">
                        <label for="mineBox">My files</label>
                    </div>
                    <div>
                        <input type="checkbox" id="expiredBox" name="expiredBox" style="margin-right: 2px" v-model="onlyExpired" @change="check($event)" class="checkbox-round">
                        <label for="expiredBox">Expired files</label>
                    </div>
                    <div>
                        <input type="checkbox" id="failedBox" name="failedBox" style="margin-right: 2px" v-model="onlyFailed" @change="check($event)" class="checkbox-round">
                        <label for="failedBox">Failed uploads</label>
                    </div>
                </div>
            </vue-good-table>
        </div>
    </div>
</template>

<script>
    import Api from '@services/Api'
    import Localize from '@localize'
    import Common from '@services/Common'
    import { EventBus, Names } from '@/EventBus'

    export default {
        data() {
            return {
                show: false,
                remainSpace: 0,
                onlyMine: true,
                onlyExpired: false,
                onlyFailed: false,
                files: [],
                users: [],
                rowSelection: []
            }
        },
        computed: {
            isAdmin: function () {
                return this.users.find(f => f.toLowerCase() == this.$msal.data.user.userName.toLowerCase())
            },
            isGuest: function () {
                return !this.$msal.data.user.userName.toLowerCase().includes("@zat");
            },
            headers: function () {
                return [
                    { label: 'ID', field: 'id' },
                    { label: 'File Name', field: 'fileName'},
                    { label: 'Size', field: 'readableSize' },
                    { label: 'User', field: 'userName', sortable: false },
                    { label: 'User email', field: 'userEmail', sortable: false, },
                    { label: 'Recipients', field: 'otherEmails', sortable: false, },
                    { label: 'Created Date', field: 'readableDate' },
                    { label: 'Message', field: 'message', sortable: false },
                    { label: 'Upload', field: 'uploadFinished', type: 'boolean ' },
                    { label: 'Email Send', field: 'emailsSend', type: 'boolean ' },
                    { label: 'Link', field: 'url', sortable: false },
                    { label: 'Delete', field: 'delete', sortable: false, hidden: !this.isAdmin }]
            },
            getRole: function () {
                //For text above logout button.
                if (this.isAdmin) {
                    return "administrator";
                }
                else if (this.isGuest) {
                    return "guest";
                }
                else {
                    return "user";
                }
            },
            filteredFiles: function () {
                var fltFiles = this.files;

                if (this.isGuest) {
                    return this.files.filter(f => f.userEmail == this.$msal.data.user.userName || f.otherEmails.includes(this.$msal.data.user.userName))
                }

                if (this.onlyMine) {
                    fltFiles = fltFiles.filter(f => f.userEmail == this.$msal.data.user.userName)
                }
                if (this.onlyExpired) {
                    fltFiles = fltFiles.filter(f => f.expired == true);
                }
                if (this.onlyFailed) {
                    fltFiles = fltFiles.filter(f => f.uploadFinished == false);
                }
                return fltFiles;
            },
            totalSize: function () {
                return Common.getReadableFileSizeString(this.files.reduce((sum, item) => sum + item.size, 0));
            },
            formatDate: function () {
                return this.files.map(function (item) {
                    var newDate = new Date(item.createdDate);
                    return ((("0" + newDate.getDate()).slice(-2)) + '/' + ("0" + (newDate.getMonth() + 1)).slice(-2) + '/' + newDate.getFullYear() + ', ' + (("0" + newDate.getHours()).slice(-2)) + ':' + (("0" + newDate.getMinutes()).slice(-2)));
                });
            }
        },
        mounted() {
            if (!this.$msal.isAuthenticated()) {
                this.$msal.signIn();
            } else {
                this.$msal.acquireToken({ scopes: ['user.read', 'profile'] }).then(async a => {
                    if (a && a['idToken']) {
                        sessionStorage.setItem("accessToken", a['idToken'].rawIdToken);
                    }
                    await Api.serviceInfo();
                    Api.getAllowedUsers().then(a => this.users = a.data)
                    this.allFiles()
                })
            }
        },
        methods: {
            async allFiles() {
                try {
                    await Api.serviceInfo()
                    let response = await Api.allFiles(sessionStorage.getItem('accessToken'))
                    let remain = await Api.getRemainSpace(sessionStorage.getItem('accessToken'))
                    this.remainSpace = Common.getReadableFileSizeString(remain.data)
                    this.files = response.data.files
                    this.show = true

                    if (isAdmin || isGuest) {
                        this.onlyMine = false;
                    }
                    else {
                        this.onlyMine = true;
                    }

                } catch (error) {
                    localStorage.clear();
                    if (error == "403")
                        this.notify({ status: 403 })
                    else if (error == "401")
                        this.notify({ status: 401 })
                }
            },
            check(param) {
                this.$nextTick(() => {
                    console.log(param.target.id)
                });
            },
            selectionChanged(params) {
                this.rowSelection = params.selectedRows;
            },
            checkAuthExp() {
                let expirationTime = localStorage.getItem('expirationTime')
                if (expirationTime != null) {
                    let dateNow = new Date()
                    let expirationDate = new Date(expirationTime)
                    if (expirationDate < dateNow) {
                        throw "403";
                    }
                }
            },
            async deleteFiles() {
                if (confirm("Opravdu chcete vybraný soubor smazat?")) {
                    try {
                        console.log(this.rowSelection);
                        if (this.rowSelection) {
                            for (const item of this.rowSelection) {
                                await this.delete(item.fileRequestId);
                            }
                            this.notify({ status: 200 })
                            this.allFiles()
                        }
                    } catch (error) {
                        console.error(error)
                        this.notify(error.response)
                    }
                }
            },
            async deleteFile(fileRequestId) {
                if (confirm("Opravdu chcete vybraný soubor smazat?")) {
                    try {
                        await this.delete(fileRequestId)
                        this.notify({ status: 200 })
                        this.allFiles()
                    } catch (error) {
                        console.error(error)
                        this.notify(error.response)
                    }
                }
            },
            async delete(fileRequestId) {
                await Api.serviceInfo()
                await Api.adminDelete(fileRequestId)
            },
            notify(response) {
                if (response != null && response.status == 200) {
                    EventBus.$emit(Names.ShowSuccess, Localize.deleteView().message.fileDeleted)
                }
                else if (response != null && response.status == 201) {
                    EventBus.$emit(Names.ShowSuccess, Localize.login().loginLogout)
                }
                else if (response != null && response.status == 401) {
                    EventBus.$emit(Names.ShowInfo, Localize.login().loginAuthorize)
                }
                else if (response != null && response.status == 403) {
                    EventBus.$emit(Names.ShowInfo, Localize.login().tokenExpire)
                }
                else {
                    EventBus.$emit(Names.ShowError, Localize.deleteView().message.somethingWentWrong)
                }
            },
            login() {
                this.$router.push({ name: 'login' });
            },
            async logout() {
                localStorage.clear();
                this.$msal.signOut()
            }
        }
    }
</script>

<style>
    .card_admin {
        width: 90%;
        padding: 10px;
        margin: 30px;
        margin-top: 100px;
    }

    @media only screen and (min-width: 426px) {
        .card_admin {
            max-width: 90%;
        }
    }

    .card_admin h1 {
        display: inline-block;
        color: #666;
    }

    .card_admin p {
        display: inline-block;
        margin-right: 10px;
    }

    .table_admin {
    }

        .table_admin tr:nth-child(odd) {
            background-color: rgb(230,242,235) !important;
        }

        .table_admin tr:nth-child(even) {
            background-color: rgb(244,245,248) !important;
        }
    
        .table_admin thead th {
        color: rgb(0, 121, 53) !important;
        }

        .table_admin th {
            color: rgb(0, 121, 53) !important;
        }


    .button_logout {
        font-size: 16px;
        padding: 5px;
        width: 100px;
        border: 1px solid rgb(255, 255, 255);
        border-radius: 4px;
        background-color: rgb(0, 121, 52);
        color: rgb(243, 250, 253);
        border-color: rgb(0, 121, 52);
        margin: 5px;
        float: right;
        display: block
    }

        .button_logout:hover:not([disabled]) {
            background-color: rgb(63, 156, 53);
            border-color: rgb(63, 156, 53)
        }
    .column_date {
        max-width: 140px;
    }

    .column_checkbox {
        min-width: 60px;
        max-width: 70px;
    }

    .column_name {
        min-width: 250px;
    }

    .column_size {
        min-width: 80px;
    }

    .column_user {
        min-width: 190px;
    }

    .selectionInfoRow {
        background: rgb(230,242,235);
        height: 40px;
    }

    .checkbox-round {
        width: 1.3em;
        height: 1.3em;
        background-color: white;
        border-radius: 50%;
        vertical-align: middle;
        border: 1px solid #ddd;
        appearance: none;
        -webkit-appearance: none;
        outline: none;
        cursor: pointer;
    }

        .checkbox-round:checked {
            background-color: rgb(0, 121, 52);
        }

    .button_delete {
        color: red;
        border-radius: 4px;
        background-color: rgb(244,245,248);
        font-size: 18px;
        padding: 8px 8px;
        margin: 0;
        padding: 4px;
    }

        .button_delete:hover:not([disabled]) {
            color: white;
            background-color: red;
        }
</style>